import { TextField } from "@mui/material"
import { useState } from "react"
import { useDispatch } from "react-redux"
import AddNewShop from "../../queries/AddNewShop"

const WindowScreenAddNewShop = ({setOpenAddNewShop}) => {

    const dispatch = useDispatch()
    const [shopName, setShopName] = useState("")

    function addNewShopHandler(){
        AddNewShop(shopName).then((data) => {
            console.log(data)
        })
        setOpenAddNewShop(false)
    }

    return <div onClick={() => setOpenAddNewShop(false)} className="add-new-shop-container">
        <div onClick={(e) => e.stopPropagation()} className="add-new-shop-block">
            <p className="add-new-shop-header">Новый магазин</p>
            <TextField className="add-new-shop-input input-type" label="Название магазина" onChange={(e) => setShopName(e.target.value)} value={shopName} fullWidth />
            <div onClick={addNewShopHandler} className="add-new-shop-button">Добавить</div>
        </div>
    </div>
}

export default WindowScreenAddNewShop