import { _global_domain } from "../domain-address"

const AddNewShop = async(nameShop) => {

    return await fetch(_global_domain + "/add_shop", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(
            {
                name: nameShop,
                show: 1
            }
        )
    })
        .then(response => response.json())

}
export default AddNewShop