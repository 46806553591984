import { _global_domain } from "../domain-address"

const GetOrders = async() => {

    return await fetch(_global_domain + "/get_orders", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({limit: 100000, shop_id: 1})
    })
        .then(response => response.json())
}

export default GetOrders