import { useEffect, useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import Loading from "../fixed-elements/Loading"
import CheckAdmin from "../../queries/CheckAdmin"
import { useSelector } from "react-redux"

const AdminPanel = ({setOpenChangeShop}) => {

    const [isUserAdmin, setIsUserAdmin] = useState(false)
    const navigate = useNavigate()
    const shops = useSelector(s => s.app.shops)

    function getCurrentShopName() {
        let currentShop = shops.find(s => s.selected === true)
        return currentShop ? currentShop.name : "Выберите магазин"
    }

    useEffect(() => {
        let tg = window.Telegram.WebApp;
        if (tg.initDataUnsafe.user) // ВНИМАНИЕ НЕ!
        CheckAdmin(tg.initDataUnsafe.user.id).then(response => { // ВНИМАНИЕ 918184279
            setIsUserAdmin(response.result)
            !response.result && navigate("/")
        })
    }, [navigate])

    return isUserAdmin ? <div className="admin-panel-container">
        <div className="admin-panel-header">
            <h1>Админ-панель</h1>
            <div onClick={() => setOpenChangeShop(true)} className="shop-select">
                <p>{getCurrentShopName()}</p> 
                <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 -960 960 960" fill="#444444"><path d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z"/></svg>
            </div>
        </div>
        <div className="main-block">
            <NavLink to={"orders"} className="block orders-block">Заказы</NavLink>
            <NavLink to={"contacts"} className="block contacts-block">Контакты</NavLink>
            <NavLink to={"ctgs"} className="block categories-block">Категории</NavLink>
            <NavLink to={"products"} className="block products-block">Товары</NavLink>
            <NavLink to={"shops"} className="block shops-block">Магазины</NavLink>
        </div>
    </div>
    : <Loading />
}

export default AdminPanel