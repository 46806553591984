import { _global_domain } from "../domain-address"

const GetShops = async(show) => {

    return await fetch(_global_domain + "/get_shops", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({show})
    })
        .then(response => response.json())
}

export default GetShops