import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import EditShop from "../../../queries/EditShop"
import GetShops from "../../../queries/GetShops"
import Loading from "../../fixed-elements/Loading"
import CheckAdmin from "../../../queries/CheckAdmin"

function Shops({setOpenAddNewShop, setOpenEditNewShop, setShopIdInWindow, isShopsLoad, setIsShopsLoad, adminShops, setAdminShops}){

    const [isUserAdmin, setIsUserAdmin] = useState(false)
    const [isCopy, setIsCopy] = useState(false)
    const navigate = useNavigate()

    function copyToClipboard(link) {
        setIsCopy(true)
        setTimeout(() => setIsCopy(false), 1500)
        navigator.clipboard.writeText(link)
    }

    function openWindowEdit(id){
        setOpenEditNewShop(true)
        setShopIdInWindow(id)
    }

    function toggleShowShop(id){
        EditShop(id, {show: +!adminShops.find(s => s.id === id).show}).then(() => {
            setIsShopsLoad(false)
        })
    }

    
    useEffect(() => {
        let tg = window.Telegram.WebApp;
        if (tg.initDataUnsafe.user) // ВНИМАНИЕ НЕ!
        CheckAdmin(tg.initDataUnsafe.user.id).then(response => { // ВНИМАНИЕ 918184279
            setIsUserAdmin(response.result)
            !response.result && navigate("/")
        })
    }, [navigate])

    async function buildShopsArray(newData){
        let storage_shop_id = localStorage.getItem("shop_id")
        return newData.map((s) => {
            if(storage_shop_id){
                if(+s.id === +storage_shop_id) return { id: s.id, name: s.name, selected: true, show: s.show }
                else return { id: s.id, name: s.name, selected: false, show: s.show }
            }else{
                return { id: s.id, name: s.name, selected: false, show: s.show }
            }
        })
    }

    useEffect(() => {
        let localShops = []
        if(!isShopsLoad){
            GetShops(1).then(responseOn => {
                let newDataOn = Array.isArray(responseOn.result.data) ? responseOn.result.data : [responseOn.result.data]
                GetShops(0).then(responseOff => {
                    let newDataOff = Array.isArray(responseOff.result.data) ? responseOff.result.data : [responseOff.result.data]

                    buildShopsArray(newDataOn).then(data => localShops.push(...data))
                    buildShopsArray(newDataOff).then(data => localShops.push(...data))

                    setIsShopsLoad(true)
                    setAdminShops(localShops)
                })
            })
            
        }
    }, [adminShops, isShopsLoad, setIsShopsLoad, setAdminShops])

    return isUserAdmin ? <div className="shops-page-container admin-block-container">
        <div onClick={() => navigate(-1)} className="back-block">
            <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z" /></svg>
            <p>Назад</p>
        </div>
        <div className="shops-content">
            {<div className={"copy-notification " + (isCopy ? "active" : "")}>Скопированно!</div>}
            <h1>Магазины</h1>
            {!isShopsLoad && <div><Loading /></div>}
            {adminShops.map((s, idx) => <div key={s.id} className="admin-shops-item">
                <div className="admin-shops-item-wrapper">
                    <div className="item-name">
                        <p>{++idx}. {s.name}</p>
                        <div onClick={() => openWindowEdit(s.id)} className="item-edit-button">
                            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#444444"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h357l-80 80H200v560h560v-278l80-80v358q0 33-23.5 56.5T760-120H200Zm280-360ZM360-360v-170l367-367q12-12 27-18t30-6q16 0 30.5 6t26.5 18l56 57q11 12 17 26.5t6 29.5q0 15-5.5 29.5T897-728L530-360H360Zm481-424-56-56 56 56ZM440-440h56l232-232-28-28-29-28-231 231v57Zm260-260-29-28 29 28 28 28-28-28Z"/></svg>
                        </div>
                    </div>
                    <div onClick={() => toggleShowShop(s.id)} className="item-status">
                        {s.show
                            ? <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#9DC384"><path d="M240-640h360v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85h-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640Zm0 480h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM240-160v-400 400Z"/></svg>
                            : <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#D16D6A"><path d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm0-80h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z"/></svg>
                        }
                    </div>
                </div>
                <div onClick={() => copyToClipboard(`t.me/dff_flowers_bot?start=${s.id}`)} className="item-link">
                    <p>t.me/dff_flowers_bot?start={s.id}</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 -960 960 960" fill="#444444"><path d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z"/></svg>
                </div>
            </div>)}
            <div onClick={() => setOpenAddNewShop(true)} className="admin-shops-new-shop-button">
                <svg xmlns="http://www.w3.org/2000/svg" height="32px" viewBox="0 -960 960 960" width="32px" fill="#ffffff"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/></svg>
            </div>
        </div>
    </div>
    : <Loading />
}

export default Shops