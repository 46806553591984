export const DEFAULT = "DEFAULT"
export const DELETE_FROM_BASKET = "DELETE_FROM_BASKET"
export const PLUS_IN_BASKET = "PLUS_IN_BASKET"
export const MINUS_IN_BASKET = "MINUS_IN_BASKET"
export const ADD_TO_BASKET = "ADD_TO_BASKET"
export const LOAD_BASKET = "LOAD_BASKET"
export const HIDE_MENU = "HIDE_MENU"
export const TOGGLE_MENU = "TOGGLE_MENU"
export const CHANGE_CLIENT_ID = "CHANGE_CLIENT_ID"
export const CHANGE_CLIENT_USERNAME = "CHANGE_CLIENT_USERNAME"
export const CHANGE_CLIENT_NAME = "CHANGE_CLIENT_NAME"
export const CHANGE_CLIENT_NUMBER = "CHANGE_CLIENT_NUMBER"
export const CHANGE_CLIENT_ADDRESS = "CHANGE_CLIENT_ADDRESS"
export const TOGGLE_IN_HOME = "TOGGLE_IN_HOME"
export const CHANGE_NAME_CATEGORY = "CHANGE_NAME_CATEGORY"
export const NEW_CATEGORY = "NEW_CATEGORY"
export const SET_CATEGORIES = "SET_CATEGORIES"
export const DELETE_CATEGORY = "DELETE_CATEGORY"
export const ADD_NEW_IMAGE = "ADD_NEW_IMAGE"
export const MOVE_PROD_IMAGES = "MOVE_PROD_IMAGES"
export const DELETE_IMAGE = "DELETE_IMAGE"
export const DELETE_CATEGORY_FROM_PRODUCT = "DELETE_CATEGORY_FROM_PRODUCT"
export const SAVE_CATEGORY_FOR_PRODUCT = "SAVE_CATEGORY_FOR_PRODUCT"
export const SAVE_PRICE_PRODUCT = "SAVE_PRICE_PRODUCT"
export const SAVE_NAME_PRODUCT = "SAVE_NAME_PRODUCT"
export const UPDATE_PRODUCT_LIST = "UPDATE_PRODUCT_LIST"
export const SET_PRODUCTS = "SET_PRODUCTS"
export const ADD_NEW_PRODUCT = "ADD_NEW_PRODUCT"
export const DELETE_PRODUCT = "DELETE_PRODUCT"
export const TARGET_WS_INFO_POINTS_TARGET = "TARGET_WS_INFO_POINTS_TARGET"
export const CHANGE_CLIENT_POINTS = "CHANGE_CLIENT_POINTS"
export const CHANGE_SHOP = "CHANGE_SHOP"
export const SET_SHOPS = "SET_SHOPS"