import { useDispatch, useSelector } from "react-redux"
import RadioOff from "./RadioOff"
import RadioOn from "./RadioOn"
import GetCategories from "../../queries/GetCategories"
import { SET_CATEGORIES } from "../../reducers/types"

function DialogSelectShop ({setOpenChangeShop}) {

    const shops = useSelector(s => s.app.shops)
    const dispatch  = useDispatch()

    async function selectShop (id) {
      localStorage.setItem("shop-dff-store", id)
      dispatch({type: "CHANGE_SHOP", payload: {id}})
      await GetCategories(id).then(data_ctg => {
        let newData = Array.isArray(data_ctg.result.data) ? data_ctg.result.data : [data_ctg.result.data]
        dispatch({type: SET_CATEGORIES, payload: newData})
    })
      setOpenChangeShop(false)
    }

    return <div onClick={() => setOpenChangeShop(false)} className="dialog-select-shop-container">
        <div onClick={(e) => e.stopPropagation()} className="dialog-select-shop-block">
            <p className="dialog-select-shop-header">Выберите магазин</p>
            <div className="dialog-select-shop-body">
                {shops.map(s => <div key={s.id} onClick={() => selectShop(s.id)} className="dialog-select-shop-body-item">
                    {s.selected ? <RadioOn/> : <RadioOff/>}
                    {s.name}
                </div>)}
            </div>
        </div>
    </div>
}

export default DialogSelectShop