import { useSelector } from "react-redux"

function DialogStartSelectShop({setIsShopSelected}){

    const shops = useSelector(s => s.app.shops)

    function selectShop (id) {
        localStorage.setItem("shop-dff-store", id)
        setIsShopSelected(true)
    }

    return <div className="dialog-start-select-shop-container">
        <div className="dialog-start-select-shop-block">
            <div className="dialog-start-select-shop-header">
                <p className="heading">Выбор магазина</p>
                <p className="subheading">Выберите ближайший к вам магазин</p>
            </div>
            <div className="dialog-start-select-shop-body">
                {shops.map(s => <div key={s.id} onClick={() => selectShop(s.id)} className="dialog-start-select-shop-body-item">
                    <p>Магазин: {s.name}</p>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#444444"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"/></svg>
                </div>)}

            </div>
        </div>
    </div>
}

export default DialogStartSelectShop