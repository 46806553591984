import { _global_domain } from "../domain-address"

const GetCategories = async (shop_id) => {


    return await fetch(_global_domain + "/get_categories", { 
            method: 'POST', 
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({shop_id})
        })
        .then(response => response.json())

}
export default GetCategories