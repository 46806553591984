import { TextField } from "@mui/material"
import { useEffect, useState } from "react"
import EditShop from "../../queries/EditShop"

const WindowScreenEditNewShop = ({setOpenEditNewShop, shopIdInWindow, setShopIdInWindow, setIsShopsLoad, adminShops}) => {

    const [shopName, setShopName] = useState("")

    function closeWindow(){
        setOpenEditNewShop(false)
        setShopIdInWindow(-1)
    }

    function sendNewName(){
        EditShop(shopIdInWindow, {name: shopName}).then(() => {
            setIsShopsLoad(false)
            closeWindow()
        })
    }

    useEffect(() => {
        adminShops.forEach(s => s.id === shopIdInWindow && setShopName(s.name));
    }, [adminShops, shopIdInWindow])

    return <div onClick={() => closeWindow()} className="edit-new-shop-container">
        <div onClick={(e) => e.stopPropagation()} className="edit-new-shop-block">
            <p className="edit-new-shop-header">Изменение магазина</p>
            <TextField className="edit-new-shop-input input-type" label="Название магазина" onChange={(e) => setShopName(e.target.value)} value={shopName} fullWidth />
            <div onClick={sendNewName} className="edit-new-shop-button">Изменить</div>
        </div>
    </div>
}

export default WindowScreenEditNewShop