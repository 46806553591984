import { _global_domain } from "../domain-address"

const NewCategory = async (name, shop_id) => {

    return await fetch(_global_domain + "/add_category", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({name, shop_id})
    })
        .then(response => response.json())
}
export default NewCategory