import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom"
import CategoryBlockItem from "../category-block/CategoryBlockItem";
import { SET_PRODUCTS, UPDATE_PRODUCT_LIST } from "../../reducers/types";
import GetProducts from "../../queries/GetProducts";

const CategoryPage = () => {
    const params = useParams();
    const [ctg_info, setCtgInfo] = useState()
    const [current_products, setCurrentProducts] = useState()
    const categories = useSelector(s => s.app.categories)
    const products = useSelector(s => s.app.products)
    const shops = useSelector(s => s.app.shops)
    const dispatch = useDispatch()

    
    const getProductsHandler = useCallback(async(_ctg_info) => {
        await GetProducts(_ctg_info.id, 10, 0, [], shops.find(s => s.selected).id).then(response => {
            response.result &&
            dispatch({type: UPDATE_PRODUCT_LIST, payload: 
                response.result.data.map(p => {return {
                    ...p,
                    imgs: p.imgs.map((img, id) => {return {id, img}})
                }})
            })
        })
    }, [dispatch, shops])

    useEffect(() => {
        if(params.id){
            const _ctg_info = categories.filter(c => +params.id === +c.id)[0]
            if(_ctg_info){
                setCtgInfo(_ctg_info)
                setCurrentProducts(products.filter(p => p.ctgs.some(c => +c === +_ctg_info.id)))
            }
        }
    }, [categories, params, products])

    useEffect(() => {
        dispatch({type: SET_PRODUCTS, payload: []})
        const _ctg_info = categories.filter(c => +params.id === +c.id)[0]
        _ctg_info && getProductsHandler(_ctg_info)

    }, [dispatch, categories, params.id, getProductsHandler])


    return !ctg_info
        ? <div>Такой категории нет</div>
        : <div className="ctg-page-container">
            <div className="heading"><h1>{ctg_info.name}</h1></div>
            <div className="blocks ctg-page-blocks">
                {current_products.length 
                ?current_products.map(p => <div key={p.id} className="block">
                    <CategoryBlockItem product={p} />
                </div>)
                :<div>Пока что в категории нет товаров</div>}
            </div>
        </div>
}

export default CategoryPage