import { _global_domain } from "../domain-address"

const EditShop = async(shop_id, fields) => {

    return await fetch(_global_domain + "/edit_shop", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id,
        fields,
      }),
    }).then((response) => response.json())

}
export default EditShop